// import React from 'react';
// import { useOktaAuth } from '@okta/okta-react';
// import Toast from 'light-toast';
// import { ACCESS_TOKEN, POWER_APP_URL, USER_DATA } from '../assets/constants/constants';

// function Header() {
//   const { oktaAuth, authState } = useOktaAuth();
//   const sessionStorage = null;
//   const login = async () => { await oktaAuth.signInWithRedirect(); }
//   const logout = async () => {
//     Toast.loading("Signing out...", () => { });
//     await oktaAuth.signOut();
//     oktaAuth.tokenManager.clear();
//     sessionStorage.clear();
//     localStorage.removeItem(USER_DATA);
//     localStorage.removeItem(ACCESS_TOKEN);
//     Toast.hide();
//     window.location = "/login"
//   }
//   const sessionData = JSON.parse(localStorage.getItem(USER_DATA));
//   // console.log("User Data on Header", sessionData);
//   const loggedIn = (authState.isAuthenticated && sessionData !== null)
//     ? true : false;
//   const userNameText = (authState.isAuthenticated && sessionData !== null)
//     ? sessionData.profile.lastName + ", " + sessionData.profile.firstName : <></>;

//   // const userText = (authState.isAuthenticated && sessionData !== null)
//   //   ? <button className="u-image u-image-5 login-button" style={{ backgroundColor: 'rgb(255, 85, 95)', marginTop: -45, marginLeft: '80vw', borderRadius: 0, color: 'white' }} onClick={logout}>Logout</button>
//   //   : (authState.isAuthenticated && sessionData !== null) ? <button className="u-image u-image-5 login-button" style={{ backgroundColor: 'rgb(255, 85, 95)', marginTop: -45, marginLeft: '80vw', borderRadius: 0, color: '#001A30' }} onClick={login}>Sign In</button> : <></>;

//   const userLoginIcon = (authState.isAuthenticated && sessionData !== null)
//     ? <span style={{
//       paddingRight: 10, paddingLeft:10, paddingTop:3, paddingBottom:3, backgroundColor: '#FF555F', borderRadius: 0, color: '#fff', fontSize: "12px" }} onClick={logout} >Logout</span>
//     : authState.isAuthenticated ? <button className="u-image u-image-5 fa fa-sign-in login-icon" style={{ backgroundColor: '#001A30', borderRadius: 0, color: '#001A30', fontSize: "22px" }} onClick={login} /> : <></>;

//   return (
//     <header className="u-clearfix u-custom-color-2 u-header u-sticky u-header" id="sec-4618" style={{ backgroundColor: '#073262' }}>
//       <div className="u-clearfix u-sheet u-sheet-1">
//         {/* <a href="https://genpact.com" className="u-image u-logo u-image-1" data-image-width={400} data-image-height={265}>
//           <img src={require("../assets/images/1.svg").default} alt="" className="u-logo-image u-logo-image-1" data-image-width={64} /></a>
//          */}
//         <div className="row" style={{height:56}}>
//           <div className="col-md-1 col-xs-8" style={{ position:"relative",left:"25px" }} >
//             <a target="_blank" href="/https://app.coraops360.genpact.com/"
//               style={{display:"block",width:"100px"}}>
//               <img src={require("../assets/images/genpact_logo_latest.png")} alt="" className="u-image u-image-default u-image-2 header_logo" data-image-width={406} data-image-height={506} />
//             </a>
//           </div>
//           <div className="col-md-2  col-xs-4" style={{ height: 56, lineHeight:0.6 }}>
//             <ul>
//               <li className="vl" style={{height:23 }}><a href="/#"><span style={{fontSize: 18}}>ProGear 2.0</span></a></li>
//               {/* <li className="vl" style={{fontSize: "12px" }}>Powered by Lean Digital Transformation</li> */}
//               {/* <li className="vl" style={{height:19 }}><img style={{ marginTop: 15, maxHeight:42 }} src={require("../assets/images/poweredby.png")} alt="" className="u-image u-image-default u-image-2 header_logo_enterprise" data-image-width={406} data-image-height={506} /></li> */}
//             </ul>
//           </div>
//           <p className="visible-md-block visible-sm-block visible-xs-block" style={{ marginTop: -95 }}>&nbsp;</p>
//           <div className="col-md-9 col-xs-12"  style={{height:56, lineHeight:0.6}}>
//             {
//             loggedIn && (
//                 <>
//                     < ul >
//                   <li><a href="/home">Home</a></li>
//                   <li className="vl"><a href="/submit-page">Submit ideas</a></li>
//                   <li className="vl"><a href="/review-page">View ideas</a></li>
//                     <li className="vl"><a rel="noreferrer" href={POWER_APP_URL} target="_blank">GB+ Center</a></li>
//                   {
//                   (authState.isAuthenticated && sessionData !== null) && (
//                     <>
//                       <li className="vl" style={{paddingTop:5, paddingRight:15,paddingLeft:15}}>
//                         {
//                               userNameText
//                         }
                      
//                       </li>
//                           <li className="vl" style={{marginRight:-15}}><a href="/#">

//                           {
//                             userLoginIcon
//                           }
//                           </a>
//                           </li>
//                      </>
//                   )
//                 }

//               </ul>
//               </>
//               )
//             }
//           </div>
//         </div>
//       </div>
//     </header>

//   );
// }

// export default Header;



import React, { useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import Toast from "light-toast";
import {
  ACCESS_TOKEN,
  POWER_APP_URL,
  USER_DATA,
} from "../assets/constants/constants";

function Header() {
  const { oktaAuth, authState } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(authState.isAuthenticated ? authState.idToken.claims : null);
  const [userName, setUserName] = useState([])

  const login = async () => {
    await oktaAuth.signInWithRedirect({
      scopes: ['openid', 'profile', 'email', 'offline_access']
    });
  };

  // const logout = async () => {
  //   Toast.loading("Signing out...", () => { });
  //   await oktaAuth.signOut();
  //   await this.props.oktaAuth.signOut();
  //   oktaAuth.tokenManager.clear();
  //   sessionStorage.clear();
  //   localStorage.removeItem(USER_DATA);
  //   localStorage.removeItem(ACCESS_TOKEN);
  //   sessionStorage.removeItem(USER_DATA);
  //   Toast.hide();
  //   window.location = "/login";
  // };

  // const logout = async () => {
  //   console.log("logout function called")
  //   Toast.loading("Signing out...", () => { });
  //   await oktaAuth.signOut();
  //   oktaAuth.tokenManager.clear();
  //   sessionStorage.clear();
  //   localStorage.removeItem(USER_DATA);
  //   localStorage.removeItem(ACCESS_TOKEN);
  //   sessionStorage.removeItem(USER_DATA);
  //   Toast.hide();
  // };

  const logout = async () => {
    console.log("function is being called")
    oktaAuth.tokenManager.clear();
    await oktaAuth.signOut();
    dummyFunction()
  };
  const dummyFunction=()=>{
    if (!authState.isPending && !authState.isAuthenticated) {
      logout()
    }
  }
 
  useEffect(() => {
    if (authState && authState.isAuthenticated) {
      setUserInfo(authState.idToken.claims);
    }
  }, [authState, oktaAuth]);

  useEffect(() => {
    
    if (userInfo && userInfo.mail) {
      console.log("user info =>", userInfo)

      // changes made on 28-01-2025 - fix undefined value error 
      let local_firstName = "";
      let local_lastName = "";
      
      if (userInfo.name) {
        local_firstName = userInfo.name.split(", ")[1];
        local_lastName = userInfo.name.split(", ")[0];
      }

      const userData = {
        id: userInfo.idp,
        profile: {
          // firstName: userInfo.name.split(", ")[1],
          // lastName: userInfo.name.split(", ")[0],
          firstName: local_firstName ? local_firstName : "",
          lastName: local_lastName ? local_lastName : "",
          locale: "en_US",
          login: userInfo.preferred_username,
          oHRId: userInfo.preferred_username.substring(0, 9),
          timeZone: "America/Los_Angeles",
        },
      };

      console.log("header page user data =>",userData)
      if (userData) {
        let userName = `${userData.profile.lastName}, ${userData.profile.firstName}`;
        console.log("complete username =>",userName)
        setUserName(userName)
      }
      if(authState)
      {
        if(authState.idToken){
          localStorage.setItem(ACCESS_TOKEN, authState.idToken.value);
        }
      }
      
      //localStorage.setItem(ACCESS_TOKEN, authState.idToken.value);
      localStorage.setItem(USER_DATA, JSON.stringify(userData));
      sessionStorage.setItem(USER_DATA, JSON.stringify(userData));
    }

  }, [userInfo, authState]);

  const loggedIn = authState.isAuthenticated;
  const sessionData = JSON.parse(sessionStorage.getItem(USER_DATA));

  const userLoginIcon =
    loggedIn && sessionData ? (
      <span
        style={{
          paddingRight: 10,
          paddingLeft: 10,
          paddingTop: 3,
          paddingBottom: 3,
          backgroundColor: "#FF555F",
          borderRadius: 0,
          color: "#fff",
          fontSize: "12px",
        }}
        onClick={logout}
      >
        Logout
      </span>
    ) : loggedIn ? (
      <button
        className="u-image u-image-5 fa fa-sign-in login-icon"
        style={{
          backgroundColor: "#001A30",
          borderRadius: 0,
          color: "#001A30",
          fontSize: "22px",
        }}
        onClick={login}
      />
    ) : null;

  return (
    <header
      className="u-clearfix u-custom-color-2 u-header u-sticky u-header"
      id="sec-4618"
      style={{ backgroundColor: "#073262" }}
    >
      <div className="u-clearfix u-sheet u-sheet-1">
        <div className="row" style={{ height: 56 }}>
          <div
            className="col-md-1 col-xs-8"
            style={{ position: "relative", left: "25px" }}
          >
            <a
              target="_blank"
              href="/https://app.coraops360.genpact.com/"
              style={{ display: "block", width: "100px" }}
            >
              <img
                src={require("../assets/images/genpact_logo_latest.png")}
                alt=""
                className="u-image u-image-default u-image-2 header_logo"
                data-image-width={406}
                data-image-height={506}
              />
            </a>
          </div>
          <div
            className="col-md-2  col-xs-4"
            style={{ height: 56, lineHeight: 0.6 }}
          >
            <ul>
              <li className="vl" style={{ height: 23 }}>
                <a href="/#">
                  <span style={{ fontSize: 18 }}>ProGear 2.0</span>
                </a>
              </li>
            </ul>
          </div>
          <p
            className="visible-md-block visible-sm-block visible-xs-block"
            style={{ marginTop: -95 }}
          >
            &nbsp;
          </p>
          <div
            className="col-md-9 col-xs-12"
            style={{ height: 56, lineHeight: 0.6 }}
          >
            {loggedIn && (
              <>
                <ul>
                  <li>
                    <a href="/home">Home</a>
                  </li>
                  <li className="vl">
                    <a href="/submit-page">Submit ideas</a>
                  </li>
                  <li className="vl">
                    <a href="/review-page">View ideas</a>
                  </li>
                  <li className="vl">
                    <a rel="noreferrer" href={POWER_APP_URL} target="_blank">
                      GB+ Center
                    </a>
                  </li>
                  {loggedIn && sessionData && (
                    <>
                      <li className="vl" style={{ paddingTop: 5, paddingRight: 15, paddingLeft: 15 }}>
                        {userName ? (
                          <span>{userName}</span>
                        ) : ""}
                      </li>
                      <li className="vl" style={{ marginRight: -15 }}>
                        <a href="/#">{userLoginIcon}</a>
                      </li>
                    </>
                  )}
                </ul>
              </>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;